<template>
    <div>
        <div class="card" style="margin:20px auto;width:90%">
            <div class="card-header" style="text-align:center;background-color:#28a745;color:#fff;font-size:18px;">
                韵晴皮具小程序登录
                <div class="hidden-md">(YUN QING LEATHERWARE CO.,LTD)</div>
            </div>
            <div class="card-body">
                <b-form @submit="onSubmit" @reset="onReset" ref="loginForm">
                    <b-form-group id="groupUserName" label="登录用户名:" label-for="userName">
                        <b-form-input
                            id="userName"
                            v-model="form.userName"
                            placeholder="请输入登录用户名"
                            required>
                        </b-form-input>
                        <b-form-invalid-feedback :state="checkFormat(this.form.userName)">
                            格式不正确
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupPassword" label="密码:" label-for="password">
                        <b-form-input
                            id="password"
                            v-model="form.password"
                            type="password"
                            placeholder="请输入登录密码"
                            required>
                        </b-form-input>
                        <b-form-invalid-feedback :state="checkFormat(this.form.password)">
                            格式不正确
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupVerifyCode" label="验证码:" label-for="verifyCode">
                        <div style="display:flex;flex-direction:row;justify-content:flex-start;align-items:center;width:100%;">
                            <div style="width:50%">
                                <b-form-input
                                    id="verifyCode"
                                    v-model="form.verifyCode"
                                    placeholder="请输入验证码"
                                    required>
                                </b-form-input>
                                <b-form-invalid-feedback :state="this.form.verifyCode == this.identifyCode">
                                    验证码不正确
                                </b-form-invalid-feedback>
                            </div>
                            <div @click="refreshCode" style="width:50%;cursor: pointer;margin-top:-18px;margin-left:10px;">
                                <s-identify :identifyCode="identifyCode"></s-identify>
                            </div>
                        </div>
                    </b-form-group>
                    <div style="text-align:center">
                        <b-button type="submit" variant="primary" style="margin-right:5px;">确定</b-button>
                        <b-button type="reset" variant="secondary" style="margin-left:5px;">重置</b-button>
                    </div>
                </b-form>
            </div>
        </div>
        <div style="text-align:center;margin-top:30px;">
            软件著作权登记号: 2022SR0296581
        </div>
        <div style="text-align:center;margin-top:10px;">
            <b-link href="https://beian.miit.gov.cn" target="_blank">粤ICP备20062281号</b-link> | Copyright © {{year}}
        </div>
        <div style="text-align:center;margin-top:10px;">
            <span>External Link: </span>
            <b-link href="https://www.smart-handbag.com" target="blank" title="Smart Handbag" >
            Smart Handbag
            </b-link>
        </div>
    </div>
</template>

<script>
import { login, getUser } from 'api/user';
import SIdentify  from 'components/page/sidentify';

import { 
    Toast, 
    MessageBox 
} from 'mint-ui';

export default {
    components: { SIdentify },
    data() {
        return {
            title: "韵晴皮具小程序信息管理系统",
            form: {
                userName: '',
                password: '',
                verifyCode: ''
            },
            year: '',
            identifyCodes: "1234567890",
            identifyCode: ""
        }
    },
    computed: {
        
    },
    created() {
        this.year = new Date().getFullYear();
    },
    methods: {
        checkFormat(value) {
            let reg = /^[0-9a-zA-Z!]*$/;
            return reg.test(value);
        },
        onSubmit(event) {
            event.preventDefault();
            let form = this.$refs['loginForm'];
            let valid = form.checkValidity();
            if(valid == false) {
                MessageBox('登录失败', '登录失败');
                return;
            }
            if(this.form.verifyCode != this.identifyCode) {
                MessageBox('登录失败', '请输入正确的验证码');
                this.form.verifyCode = '';
                this.makeCode(this.identifyCodes, 4);
                return false;
            }
            login(this.form.userName, this.form.password, res => {
                if(res.status == 200) {
                    this.clearLocalItems();
                    let user = {
                        name: "user", 
                        value: {
                            userName: this.form.userName
                        },
                        expires: 8 * 60 * 60 * 1000
                    };
                    this.setLocalItem(user);
                    this.setLocalItem({
                        name: "login_time", 
                        value: new Date()
                    });
                    this.setLocalItem({
                        name: "token", 
                        value: res.data.access_token
                    });
                    getUser(this.form.userName, response => {
                        if(response.status == 200) {
                            let { data } = response.data;
                            if(data) {
                                if(!data.status || data.status == false) {
                                    this.form.userName = '';
                                    this.form.password = '';
                                    this.clearLocalItems();
                                    MessageBox('登录失败', '用户已禁用');
                                    return false;
                                } 
                                Toast({
                                    message: '登录成功正在跳转',
                                    position: 'top',
                                    duration: 1000
                                });
                                
                                this.$router.push('/product');
                            }
                        } else {
                            this.form.userName = '';
                            this.form.password = '';
                            this.form.verifyCode = '';
                            this.clearLocalItems();
                            MessageBox('登录失败', '获取用户信息失败');
                            return false;
                        }
                    });
                } else {
                    this.form.userName = '';
                    this.form.password = '';
                    this.form.verifyCode = '';
                    MessageBox('登录失败', '用户名或密码错误');
                    return false;
                }
            }, error => {
                this.onReset(event);
                MessageBox('登录失败', '用户名或密码错误');
            });
        },
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min);
        },
        refreshCode() {
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
        },
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[
                    this.randomNum(0, this.identifyCodes.length)
                ];
            }
        },
        onReset(event) {
            event.preventDefault();
            this.form.userName = '';
            this.form.password = '';
            this.form.verifyCode = '';
            this.makeCode(this.identifyCodes, 4);
        }
    },
    mounted() {
        if(process.env.NODE_ENV == 'development') {
            this.form.userName = 'dtang';
            this.form.password = 'P4ssw0rd2022!';
        }
        this.clearLocalItems();
        document.title = this.title + "登录";

        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
    }
}
</script>